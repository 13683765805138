import React from 'react'
// import {
//     Button,
//     ButtonGroup,
//     Fab

// } from '@material-ui/core';

import styles from './index.module.css'

import facebookIcon from './images/facebook.png'
import twitterIcon from './images/twitter.png'
// import youtubeIcon from './images/youtube.png'
import rssIcon from './images/rss.png'
// import soundcloudIcon from './images/soundcloud.png'
import anchorIcon from './images/anchor.png'

import instagramIcon from './images/instagram.png'


export default function SocialLinks() {
    return (
        <div className={styles.socialLinks}>
            <a href="//www.facebook.com/franchisedwithshanedouglas" target="_blank" rel="noopener noreferrer">
                <img src={facebookIcon} alt="Facebook icon" />
            </a>
            <a href="//twitter.com/thefranchisesd" target="_blank" rel="noopener noreferrer">
                <img src={twitterIcon} alt="Twitter icon" />
            </a>
            <a href="//www.instagram.com/franchisedwithshanedouglas" target="_blank" rel="noopener noreferrer">
                <img src={instagramIcon} alt="Instagram icon" />
            </a>
            <a href="https://anchor.fm/franchised" target="_blank" rel="noopener noreferrer">
                <img src={anchorIcon} alt="Anchor FM icon" />
            </a>
            <a href="//anchor.fm/s/dcf1798/podcast/rss" target="_blank" rel="noopener noreferrer">
                <img src={rssIcon} alt="RSS icon" />
            </a>
        </div>
    )
}