import { createClient } from 'contentful'

const API_CONFIG = {
    spaceId: 'grmhedmg1tbj',
    accessToken: 'GZoFaT4a471_6xEX7ccFhD1VLjCaMnvwqyk1bI2kpEY'
}


class Contentful {
    constructor() {

        this.authorized = ''
        this.client = ''
        const contentfulApp = this.initClient(API_CONFIG);



        // console.log(contentfulApp)

        this.providers = {
            client: contentfulApp
        };

    }

    // INIT CLIENT
    initClient(config) {

        this.client = createClient({
            space: config.spaceId,
            accessToken: config.accessToken,
            host: 'cdn.contentful.com'
        })

        return this.client.getSpace()
            .then((space) => {
                this.authorized = true
                return space
            })
    }

    loadTestData(contentTypeId) {

        // console.log(contentTypeId)
        // console.log(this)

        return this.client.getEntries({
            content_type: contentTypeId
        }).then(payload => {
            return payload.items
        })
    }

    // END CLASS
}


export default Contentful;
