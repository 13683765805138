export default {
    palette: {
        primary: {
            main: '#000000',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#EBB127',
            contrastText: '#FFFFFF'
        },
        error: {
            main: '#ffffff'
        }
    },
    text: {
        primary: {
            main: '#ffffff'
        }
    },
    typography: {
        useNextVariants: true
    }
}
